import Vue from 'vue'

import { loadConfiguration } from '@/appsettings'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

async function main() {
  await loadConfiguration()
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}

main()
