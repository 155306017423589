
import Vue from 'vue'
import ServicesComponent from '@/components/ServicesComponent.vue'

export default Vue.extend({
  name: 'HomeView',
  components: {
    ServicesComponent,
  },
})
