import axios from 'axios'

export interface Configuration {
  cmsApiBaseUrl: string
}

let CONFIGURATION: Promise<Configuration> | undefined

export async function loadConfiguration(): Promise<Configuration> {
  if (!CONFIGURATION) {
    CONFIGURATION = axios
      .get<Configuration>('/appsettings.json')
      .then((response) => response.data)
  }
  return CONFIGURATION
}

export default class appSettings {}
