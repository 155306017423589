
import axios from 'axios'
import _ from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ServicesComponent',
  props: {},
  data() {
    return {
      dataServices: [],
    }
  },
  computed: {
    services() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const services = this.dataServices
      return _.map(services, (service) => {
        return {
          id: service.id,
          title: service.title,
          category: _.get(service, 'category.name', ''),
          photoUrl: _.get(service, 'partners.logo.url', ''),
          bannerUrl: _.get(service, 'banner.url', ''),
          description: service.short_description,
        }
      })
    },
  },
  methods: {
    getServices() {
      axios
        .get(
          'https://happydom-api.happytal.com/extra-services?institutions.institution_id=1368',
        )
        .then((response) => (this.dataServices = _.get(response, 'data', null)))
    },
  },
  mounted() {
    this.getServices()
  },
})
